import React from 'react';

function RadioButton(props) {
  const {
    name, onChange, checked, disabled, dataTestId,
  } = props;

  return (
    <label
      htmlFor={`radio-${name}`}
      className='radio-button'
      data-test-id={dataTestId}
      aria-label={`radio-${name}`}
    >
      <input
        id={`radio-${name}`}
        type='radio'
        name={name}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
        hidden
      />
      <span />
    </label>
  );
}

export default RadioButton;
