import React from 'react';
import { connect } from 'react-redux';
import { isDev } from '../../../../utils';
import { functionCreator } from '../../../../utils/helpers/functionCreator';

const WarningBar = ({ warningBar, originalRole, user }) => {
  const isAdmin = originalRole === 'SUPER' || originalRole === 'ADMIN';

  const originalUserId = user?.id ?? 0;
  const impersonateId = localStorage.getItem('impersonate_id');
  const userId = impersonateId || originalUserId;
  const isDeveloper = isDev(userId);
  const isVisibleToUser = isAdmin || isDeveloper;

  const isVisible = isVisibleToUser && (warningBar.isVisible || localStorage.getItem('show_freelancer_warning') === 'true');
  const amount = warningBar.amount || parseInt(localStorage.getItem('uncomplete_umount'), 10);

  const {
    success: isWarningBarClickValid,
    function: onWarningBarClick,
  } = functionCreator(warningBar.onClickString, warningBar.functionContext);
  const handleWarningBarClick = isWarningBarClickValid
    ? onWarningBarClick
    : () => {};

  return (
    isVisible ? (
      <div className='warning-bar'>
        <div className='title-container'>
          <div className='icon'>i</div>
          <div className='title'>
            {'You need to '}
            <span
              onClick={handleWarningBarClick}
              onKeyDown={handleWarningBarClick}
              role='button'
              tabIndex={0}
            >
              {`complete ${amount} projects`}
            </span>
            {' before you can invoice your earnings.'}
          </div>
        </div>
      </div>
    ) : null
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
  warningBar: state.warningBar,
  originalRole: state.auth?.user?.originalType ?? '',
});

export default connect(mapStateToProps, null)(WarningBar);
