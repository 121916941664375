import React, { useEffect } from 'react';
import { connect, Provider, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';

import {
  BrowserRouter as Router, Redirect, Route, Switch,
} from 'react-router-dom';

import store, { persistor } from '../store/index';
import { receiveAuth } from '../actions/auth';
import getApiDataAction from '../actions/api-data';

import 'react-toastify/dist/ReactToastify.css';

// router
// pages from container folder
import Routes from './Routes';

import Alert from './core/components/Alert';
import PlatformUpdateBar from './core/components/PlatformUpdateBar';
import UpdatingLoader from './core/components/UpdatingLoader';
import WarningBar from './core/components/WarningBar';
import { AVAILABLE_LANGUAGES } from '../constants';
import { useModal } from '../hooks';
import UpdatingLoaderLocal from './core/components/UpdatingLoaderLocal';

class DummyLayout extends React.Component {
  componentDidMount() {
    this.handleUpdate();

    const { user } = this.props;
    if (user && user.user) {
      window.Appcues?.identify?.(user.user.id, {
        name: user.user.name,
        email: user.user.email,
        role: user.user.role,
        userType: user.user.type,
      });
    }
  }

  componentDidUpdate(prevProps) {
    // NOTE: in order to have access to this information, you will need
    // to wrap this component in the `withRouter` HOC

    const { location: { pathname } } = window;
    const previousLocation = prevProps?.location?.pathname;

    if (pathname !== previousLocation) {
      window.Appcues?.page?.();
    }
  }

  handleUpdate = () => {
    const { user, getApiData } = this.props;

    if (!user || !user.isAuthenticated) {
      return;
    }

    getApiData();
  };

  render() {
    return null;
  }
}

const mapStateToProps = state => ({
  user: state.auth,
  role: state.auth?.user?.type ?? '',
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getApiData: getApiDataAction,
}, dispatch);

const Dummy = connect(mapStateToProps, mapDispatchToProps)(DummyLayout);

const FetchingAuthOnMount = ({ onFetchingAuthEnd }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(receiveAuth()).finally(() => {
      onFetchingAuthEnd();
    });
  }, []);

  return (
    <UpdatingLoaderLocal
      isLoading
      withoutMessage
    />
  );
};

export default function Component() {
  const {
    isOpen: isAuthFetching,
    handleClose: endAuthFetching,
  } = useModal({ defaultIsOpen: true });

  return (
    <Provider store={store}>
      <PersistGate
        loading={null}
        persistor={persistor}
      >
        {isAuthFetching ? (
          <FetchingAuthOnMount
            onFetchingAuthEnd={endAuthFetching}
          />
        ) : (
          <>
            <Dummy />
            <Alert />
            <PlatformUpdateBar />
            <UpdatingLoader />
            <WarningBar />
            <Router>
              <Switch>
                <Route
                  path='/:lang'
                  component={Routes}
                />
                <Redirect to={`/${AVAILABLE_LANGUAGES[0]}`} />
              </Switch>
            </Router>
            <ToastContainer
              position='bottom-right'
              hideProgressBar
            />
          </>
        )}
      </PersistGate>
    </Provider>
  );
}
